/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { usStates } from '../../data/usStates';
import { InputItem } from '../shared/EstateInputItem';
import { useDispatch, useSelector } from 'react-redux';
import { TabPane, Tab, Input, Select, Icon, Button, Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react';
import { setEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { BuildEstateQuestionnaireStructure } from '../shared/EstateGeneralQuestionnaire';

export const General = ({ docId }) => {
    const [activeAccordion, setActiveAccordion] = useState(0);
    const [willProperties, setWillProperties] = useState([]);
    const [revProperties, setRevProperties] = useState([]);
    const dispatch = useDispatch();
    const estateManualInput = useSelector(state => state.estateManualInput);
    const docs = useSelector(state => state.estateManualInputDocuments);
    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];

    const descendantsStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        width: '100%',
        paddingLeft: `0px`,
        paddingBottom: '10px', borderBottom: '1px solid #f0f0f0',
    };

    const propertiesValues = {
        'estateQuestion___will_realEstateDetail_JSON': willProperties,
        'estateQuestion___rev_realEstateDetail_JSON': revProperties,
    };
    const propertiesSave = {
        'estateQuestion___will_realEstateDetail_JSON': setWillProperties,
        'estateQuestion___rev_realEstateDetail_JSON': setRevProperties,
    };

    const handleAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeAccordion === index ? -1 : index;
        setActiveAccordion(newIndex);
    };

    const addProperty = (datapoint) => {
        let list = JSON.parse(estateManualInput?.[docId]?.[datapoint] || '[]');
        list.push({
            asset: '',
            beneficiaries: [
                {
                    'name': '',
                    'percentage': 0,
                    'type': "%"
                }
            ],
        });

        list = JSON.stringify(list);
        const stat = JSON.parse(JSON.stringify(propertiesValues[datapoint]));
        stat.push({ asset: '', beneficiaries: [] });
        propertiesSave[datapoint](stat);
        dispatch(setEstateManualInputField(docId, datapoint, list));
    };

    const addBeneficiary = (datapoint, property) => {
        let list = JSON.parse(estateManualInput?.[docId]?.[datapoint] || '[]');
        list[property].beneficiaries.push({
            'name': '',
            'percentage': 0,
            'type': "%"
        });
        list = JSON.stringify(list);
        dispatch(setEstateManualInputField(docId, datapoint, list));
    };

    const removeBeneficiary = (datapoint, property, ind) => {
        let list = JSON.parse(estateManualInput?.[docId]?.[datapoint] || '[]');
        const newList = [];

        if (list[property].beneficiaries.length === 1) {
            list.forEach((item, index) => {
                if (property !== index) newList.push(item);
            });
            list = newList;
        } else {
            list[property].beneficiaries.forEach((item, index) => {
                if (ind !== index) newList.push(item);
            });
            list[property].beneficiaries = newList;
        }

        list = JSON.stringify(list);
        dispatch(setEstateManualInputField(docId, datapoint, list));
    };

    const setPropertyValue = (datapoint, property) => {
        return (e, elem) => {
            const properties = JSON.parse(JSON.stringify(propertiesValues[datapoint]));
            if (!properties[property]) {
                properties[property] = {};
            }
            properties[property].asset = elem.value;
            propertiesSave[datapoint](properties);
        };
    };

    const setBeneficiaryValue = (dataType, datapoint, property, ind) => {
        return (e, elem) => {
            const properties = JSON.parse(JSON.stringify(propertiesValues[datapoint]));

            if (!properties[property]) {
                const elements = Array.apply(null, {length: ind}).map(Number.call, Number);
                properties[property] = {
                    beneficiaries: elements.map(() => ({ name: '' })),
                };
            }

            if (!properties[property].beneficiaries?.[ind]) {
                properties[property].beneficiaries[ind] = { name: '' };
            }

            properties[property].beneficiaries[ind][dataType] = elem.value;
            propertiesSave[datapoint](properties);
        };
    };

    const changePropertyName = (datapoint, property) => {
        return (e) => {
            let list = JSON.parse(estateManualInput?.[docId]?.[datapoint] || '[]');
            list[property].asset = e.target.value;
            list = JSON.stringify(list);
            dispatch(setEstateManualInputField(docId, datapoint, list));
        };
    };

    const changeBeneficiary = (dataType, datapoint, property, ind) => {
        return (e) => {
            let list = JSON.parse(estateManualInput?.[docId]?.[datapoint] || '[]');
            list[property].beneficiaries[ind][dataType] = e.target.value;
            list = JSON.stringify(list);
            dispatch(setEstateManualInputField(docId, datapoint, list));
        };
    };

    useEffect(() => {
        const irrevNumber = Object.values(estateManualInput).filter(itm => (itm?.type === 'Irrevocable' && resultingTrustTypes.includes(itm?.subtype))).length;
        dispatch(setEstateManualInputField(docId, 'estateQuestion___irrev_hasIrrev', irrevNumber >= 1 ? 'Yes' : 'No'));
        dispatch(setEstateManualInputField(docId, 'estateQuestion___irrev_number', irrevNumber || 0));
    }, [docs]);


    useEffect(() => {
        if (estateManualInput) {
            let listWill = JSON.parse(estateManualInput?.[docId]?.estateQuestion___will_realEstateDetail_JSON || '[]');
            let listRev = JSON.parse(estateManualInput?.[docId]?.estateQuestion___rev_realEstateDetail_JSON || '[]');

            setWillProperties(listWill);
            setRevProperties(listRev);

            const irrevNumber = Object.values(estateManualInput).filter(itm => (itm?.type === 'Irrevocable' && resultingTrustTypes.includes(itm?.subtype))).length;
            dispatch(setEstateManualInputField(docId, 'estateQuestion___irrev_hasIrrev', irrevNumber >= 1 ? 'Yes' : 'No'));
            dispatch(setEstateManualInputField(docId, 'estateQuestion___irrev_number', irrevNumber || 0));
        }
    }, []);

    const beneficiariesList = (beneficiariesType, assetId) => {
        const tyeOptions = [
            { value: '%', text: '%' },
            { value: '$', text: '$' },
        ];
        const editableTypes = [
            'estateQuestion___will_realEstateDetail_JSON',
            'estateQuestion___rev_realEstateDetail_JSON',
        ];
        const definersValues = {
            'estateQuestion___will_realEstateDetail_JSON': 'estateQuestion___will_realEstate',
            'estateQuestion___rev_realEstateDetail_JSON': 'estateQuestion___rev_realEstate',
        };
        const isEditable = editableTypes.includes(beneficiariesType);
        let properties = estateManualInput?.[assetId]?.[beneficiariesType] || '[]';
        properties = JSON.parse(properties);

        return (<>
            {isEditable
                && estateManualInput?.[assetId]?.[definersValues[beneficiariesType]] === 'Yes'
                && properties.length === 0
                && <div style={{ width: '100%', textAlign: 'right' }}><Button color='teal' onClick={() => addProperty(beneficiariesType)}>Add Property</Button></div>
            }

            {properties.length > 0 && <div style={{
                display: properties?.length > 0 ? 'block' : 'none',
                margin: '10px 0 10px 200px',
                paddingBottom: '10px',
            }}>
                
                {properties.map((property, index) => (<div style={{ borderBottom: '3px solid #eee', paddingBottom: '16px', marginBottom: '16px' }} key={property.asset}>
                    {!isEditable && <h4>{property.asset}</h4>}
                    {isEditable && <Input
                        style={{ width: '400px', margin: '0 30px 0 5px' }}
                        value={propertiesValues[beneficiariesType]?.[index]?.asset}
                        type="text"
                        disabled={isEditable ? false : true}
                        placeholder='Property Name'
                        onChange={setPropertyValue(beneficiariesType, index)}
                        onBlur={changePropertyName(beneficiariesType, index)}
                    />}
                    {property.beneficiaries.map((beneficiary, ind) => (<div style={{
                        marginLeft: '200px',
                        marginBottom: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }} key={`${property.asset}_${beneficiary.name}_${ind}`}>
                        <Input
                            style={{ width: '400px', margin: '0 30px 0 5px' }}
                            value={beneficiary.name}
                            disabled={isEditable ? false : true}
                            placeholder='Beneficiary Name'
                            onChange={setBeneficiaryValue('name', beneficiariesType, index, ind)}
                            onBlur={changeBeneficiary('name', beneficiariesType, index, ind)}
                        />
                        <Input
                            style={{ width: '120px', margin: '0 30px 0 5px'}}
                            value={beneficiary.percentage}
                            type="number"
                            disabled={isEditable ? false : true}
                            onChange={changeBeneficiary('percentage', beneficiariesType, index, ind)}
                        />
                        <div style={{ width: '50px', display: 'inline-block' }}>
                            <Select
                                fluid
                                options={tyeOptions}
                                defaultValue={beneficiary?.percentage !== "0" ? beneficiary.type : '%'}
                                type="number"
                                disabled={isEditable ? false : true}
                                onChange={changeBeneficiary('type', beneficiariesType, index, ind)}
                            />
                        </div>
                        {isEditable && <Icon name='delete' inverted color='black' size='large' style={{ marginLeft: '30px' }} onClick={() => removeBeneficiary(beneficiariesType, index, ind)} />}
                    </div>))}
                    {isEditable && <div style={{ width: '100%', textAlign: 'right'}}>
                        <Button color='olive' onClick={() => addBeneficiary(beneficiariesType, index)}>Add Beneficiary</Button>
                    </div>}
                </div>))}
                {isEditable && <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button color='teal' onClick={() => addProperty(beneficiariesType)}>Add Property</Button>
                </div>}
            </div>}
        </>);
    };

    const detailQuestions = [
        'estateQuestion___will_realEstateDetail_JSON',
        'estateQuestion___will_personalPropertyDetail_JSON',
        'estateQuestion___rev_realEstateDetail_JSON',
        'estateQuestion___rev_personalPropertyDetail_JSON',
    ];

    const buildQuestionnaire = (sectionType, irrevNumber = 0, activeIndex = undefined, handleClick = undefined) => {
        const sections = BuildEstateQuestionnaireStructure(irrevNumber).filter((section) => section.title === sectionType);
        let questionnnaire = [];

        if (sectionType !== 'Irrevocable Trust') {
            questionnnaire = sections.map((section, index) => (<div style={{ paddingBottom: '12px' }} key={section.title + index}>
                {section.title === 'Irrevocable Trust' && !section.subtitle && <h3>Irrevocable Trust</h3>}
                {section.subtitle && <h4>{section.subtitle}</h4>}
                {section.questions.map((question) => {
                    if (detailQuestions.includes(question.field)) {
                        return beneficiariesList(question.field, docId);
                    }
    
                    return (
                        <InputItem
                            key={question.id}
                            componentWidth={question.width}
                            options={question.options || undefined}
                            withoutPages={question.withoutPages}
                            ignoreBorder={question.ignoreBorder}
                            docType={docId}
                            label={question.label}
                            field={question.field}
                            version={question.version}
                            indent={question?.indent ? question.indent : section.subtitle ? 4 : 0}
                            isDisabled={question.isDynamic}
                            isDynamic={question.isDynamic}
                            type={question.type || 'string'}
                            visible={question.visible === undefined ? true : question.visible}
                            addOptions={question?.addOptions}
                        />
                    );
                })}
                <p style={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: '#ff7a7a',
                    marginTop: '-12px',
                }}>*Dynamic content, please change in snapshot data.</p>
            </div>));
        }

        if (sectionType === 'Irrevocable Trust') {
            const panels = Array.apply(null, {length: irrevNumber}).map(Number.call, Number);

            questionnnaire = <>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '100px',
                }}>
                    <InputItem
                        key='estateQuestion___irrev_hasIrrev'
                        componentWidth='small'
                        options={['Yes', 'No']}
                        withoutPages={true}
                        ignoreBorder={true}
                        docType={docId}
                        label='Has Irrevocable Trust?'
                        field='estateQuestion___irrev_hasIrrev'
                        version={[1, 2, 3, 4]}
                        isDisabled={true}
                        isDynamic={true}
                        type={'string'}
                        visible={true}
                    />
                    <InputItem
                        key='estateQuestion___irrev_number'
                        componentWidth='small'
                        withoutPages={true}
                        ignoreBorder={true}
                        docType={docId}
                        label='Irrevocable Trust quantity'
                        field='estateQuestion___irrev_number'
                        version={[1, 2, 3, 4]}
                        isDisabled={true}
                        isDynamic={true}
                        type={'number'}
                        visible={true}
                    />
                </div>
                <Accordion fluid styled>
                    {panels.map((panel, indexPanel) => {
                        const sect = sections.filter(section => section.index === indexPanel + 1);

                        return (<>
                            <AccordionTitle
                                active={activeIndex === indexPanel}
                                index={indexPanel}
                                onClick={handleClick}
                            ><Icon name='dropdown' />Irrevocable Trust {indexPanel + 1}</AccordionTitle>
                            <AccordionContent active={activeIndex === indexPanel}>{
                                sect.map((section, index) => (<div style={{ paddingBottom: '12px' }} key={section.title + index}>
                                    {section.subtitle && <h4>{section.subtitle}</h4>}
                                    {section.questions.map((question) => {
                                        if (detailQuestions.includes(question.field)) {
                                            return beneficiariesList(question.field, docId);
                                        }

                                        return (
                                            <InputItem
                                                key={question.id}
                                                componentWidth={question.width}
                                                options={question.options || undefined}
                                                withoutPages={question.withoutPages}
                                                ignoreBorder={question.ignoreBorder}
                                                docType={docId}
                                                label={question.label}
                                                field={question.field}
                                                version={question.version}
                                                indent={question?.indent ? question.indent : section.subtitle ? 4 : 0}
                                                isDisabled={question.isDynamic}
                                                isDynamic={question.isDynamic}
                                                type={question.type || 'string'}
                                                visible={question.visible === undefined ? true : question.visible}
                                                addOptions={question?.addOptions}
                                            />
                                        );
                                    })}
                                    <p style={{
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        color: '#ff7a7a',
                                        marginTop: '-12px',
                                    }}>*Dynamic content, please change in snapshot data.</p>
                                </div>))
                            }</AccordionContent>
                        </>);
                    })}
                </Accordion>
            </>
            ;
        }

        return (questionnnaire);
    };

    const resultingTrustTypes = [
        'General Irrevocable/Dynasty',
        'ILIT/SLAT',
        'GRAT',
        'GRUT',
        'CRT',
        'QPRT',
        'IDGT',
        'Credit Shelter Trust',
    ];

    const panes = [
        { menuItem: 'Will', render: () => <TabPane>{buildQuestionnaire('Will')}</TabPane> },
        { menuItem: 'POA', render: () => <TabPane>{buildQuestionnaire('POA')}</TabPane> },
        { menuItem: 'HealthCare', render: () => <TabPane>{buildQuestionnaire('Healthcare')}</TabPane> },
        { menuItem: 'Revocable Trust', render: () => <TabPane>{buildQuestionnaire('Revocable Trust')}</TabPane> },
        {
            menuItem: 'IrrevocableTrust',
            render: () => <TabPane>{buildQuestionnaire(
                'Irrevocable Trust',
                Object.values(estateManualInput).filter(itm => (itm?.type === 'Irrevocable' && resultingTrustTypes.includes(itm?.subtype))).length,
                activeAccordion,
                handleAccordion,
            )}</TabPane>
        },
    ];

    return (
        <>
            <div style={{ marginBottom: '20px', visibility:"hidden" }}>
                <InputItem
                    checkbox
                    label="Incomplete documents"
                    withoutPages
                    docType={docId}
                    field='generalQuestion___isDocumentIncomplete'
                    version={[1,2,3,4]}
                />
            </div>

            <InputItem 
                    docType={docId}
                    label={`State of Residence`}
                    field={`stateOfResidence`}
                    nOptions={statesOptions}
                    version={[1,2,3,4]}
            />

            <InputItem docType={docId} label='Additional Information' field='additionalInformation' multiline version={[1,2,3,4]}/>

            <div style={{ marginBottom: '20px' }}>
                <div style={descendantsStyle}>
                    <p style={{ display: 'block', margin: 0, marginRight: '10px', width: '260px' }}>Marital status</p>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <ul style={{listStyle: 'none'}}>
                            <li>{estateManualInput?.[docId]?.maritalStatus || ''}</li>
                        </ul>
                    </div>
                </div>
                <div style={descendantsStyle}>
                    <p style={{ display: 'block', margin: 0, marginRight: '10px', width: '260px' }}>Children</p>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {estateManualInput?.[docId]?.children?.length > 0
                            ? <ul style={{listStyle: 'none'}}>{estateManualInput?.[docId]?.children.map((itm, ind) => (<li key={ind}>{itm}</li>))}</ul>
                            : 'There are no children registered.'}
                    </div>
                </div>
                <div style={descendantsStyle}>
                    <p style={{ display: 'block', margin: 0, marginRight: '10px', width: '260px' }}>Grandchildren</p>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {estateManualInput?.[docId]?.grandchildren?.length > 0
                            ? <ul style={{listStyle: 'none'}}>{estateManualInput?.[docId]?.grandchildren.map((itm, ind) => (<li key={ind}>{itm}</li>))}</ul>
                            : 'There are no grandchildren registered.'}
                    </div>
                </div>
            </div>

            <Tab panes={panes} />
        </>
    )
}
